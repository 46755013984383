import store from '@state/store'

export default [
  {
    path: '/',
    name: 'home',
    component: () => lazyLoadView(import('@views/home')),
  },
  {
    path: '/login',
    name: 'login',
    component: () => lazyLoadView(import('@views/login')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the to dashboard page instead
          next({
            name: 'dashboard',
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/newpassword',
    name: 'newpassword',
    component: () => lazyLoadView(import('@views/new-password')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the dashboard page instead
          next({
            name: 'dashboard',
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: () => lazyLoadView(import('@views/reset-password')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the dashboard page instead
          next({
            name: 'dashboard',
          })
        } else {
          // Continue to the reset password page
          next()
        }
      },
    },
  },
  {
    path: '/confirmresetpassword',
    name: 'confirmresetpassword',
    component: () => lazyLoadView(import('@views/confirm-password-reset')),
    props: (route) => ({
      email: route.query.email,
    }),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the dashboard page instead
          next({
            name: 'dashboard',
          })
        } else {
          // Continue to the reset password page
          next()
        }
      },
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => lazyLoadView(import('@views/profile')),
    meta: {
      authRequired: true,
    },
    props: (route) => ({
      user: store.state.auth.currentUser,
    }),
  },
  // {
  //   path: '/profile/:email',
  //   name: 'email-profile',
  //   component: () => lazyLoadView(import('@views/profile')),
  //   meta: {
  //     authRequired: true,
  //   },
  //   beforeResolve(routeTo, routeFrom, next) {
  //     store
  //       // Try to fetch the user's information by their email
  //       .dispatch('users/fetchUser', { email: routeTo.params.email })
  //       .then(user => {
  //         // Add the user to the route params, so that it can
  //         // be provided as a prop for the view component below.
  //         routeTo.params.user = user
  //         // Continue to the route.
  //         next()
  //       })
  //       .catch(() => {
  //         // If a user with the provided email could not be
  //         // found, redirect to the 404 page.
  //         next({ name: '404', params: { resource: 'User' } })
  //       })
  //   },
  //   // Set the user from the route params, once it's set in the
  //   // beforeEnter route guard.
  //   props: route => ({ user: route.params.user }),
  // },
  {
    path: '/qr',
    redirect: {
      name: 'dashboard',
    },
  },
  {
    path: '/qr/:id',
    name: 'qrcode',
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        const id = routeTo.params.id
        // var request = new XMLHttpRequest()
        // new Promise(function(resolve, reject) {
        //   // Setup listener to process completed requests
        //   request.onreadystatechange = function() {
        //     // Only run if the request is complete
        //     if (request.readyState !== 4) return
        //     // Process the response
        //     if (request.status >= 200 && request.status < 300) {
        //       resolve(request)
        //     } else {
        //       reject({
        //         status: request.status,
        //         statusText: request.statusText,
        //       })
        //     }
        //   }

        //   // Setup HTTP request
        //   request.open('GET', 'https://8iqziisoq3.execute-api.us-east-2.amazonaws.com/staging/qr/' + id, true)
        //   // Send the request
        //   request.send()
        // })
        fetch(
          (process.env.VUE_APP_DEV_ENV === 'staging'
            ? 'https://6p9rar596f.execute-api.us-east-2.amazonaws.com/staging/qr/'
            : process.env.NODE_ENV === 'production'
            ? 'https://8iqziisoq3.execute-api.us-east-2.amazonaws.com/production/qr/'
            : 'http://localhost:8080/query') + id,
          {
            mode: 'cors',
          }
        )
          .then(async (response) => {
            const j = await response.json()
            if (!j.ok) {
              throw new Error(j.message)
            }
            return j
          })
          .then((data) => {
            const deviceId = data.deviceId
            if (deviceId) {
              next({
                name: 'device-detail',
                params: {
                  id: deviceId,
                },
              })
              return
            }
            next({
              name: 'home',
            })
          })
          .catch((err) => {
            store.dispatch('messages/setNewMessage', {
              message: err.message,
              type: 'warning',
            })
            next({
              name: 'home',
            })
          })
        // store.dispatch('auth/logOut').finally(() => {
        //   const authRequiredOnPreviousRoute = routeFrom.matched.some(
        //     route => route.meta.authRequired
        //   )
        //   // Navigate back to previous page, or home as a fallback
        //   next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('auth/logOut').finally(() => {
          const authRequiredOnPreviousRoute = routeFrom.matched.some(
            (route) => route.meta.authRequired
          )
          // Navigate back to previous page, or home as a fallback
          next(
            authRequiredOnPreviousRoute
              ? {
                  name: 'home',
                }
              : {
                  ...routeFrom,
                }
          )
        })
      },
    },
  },
  {
    path: '/locations',
    name: 'locations',
    meta: {
      authRequired: true,
    },
    component: () => lazyLoadView(import('@views/locations')),
    props: (route) => ({
      signedin: store.getters['auth/loggedIn'],
    }),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      authRequired: true,
    },
    component: () => lazyLoadView(import('@views/bv-dashboard')),
    props: (route) => ({
      signedin: store.getters['auth/loggedIn'],
    }),
  },
  {
    path: '/location/:id',
    name: 'location-detail',
    meta: {
      authRequired: true,
    },
    component: () => lazyLoadView(import('@views/location')),
    props: (route) => ({
      signedin: store.getters['auth/loggedIn'],
      id: route.params.id,
    }),
  },
  // {
  //   path: '/gantt',
  //   name: 'gantt',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => lazyLoadView(import('@views/gantt')),
  // },
  {
    path: '/devices',
    name: 'devices',
    meta: {
      authRequired: true,
    },
    component: () => lazyLoadView(import('@views/devices')),
    // Set the location for device list from the route params
    props: (route) => ({
      // locationFilter: route.params.locationFilter,
      locationId: route.query.locationId,
      locationName: route.query.locationName,
    }),
  },
  {
    path: '/device/:id',
    name: 'device-detail',
    meta: {
      authRequired: true,
    },
    component: () => lazyLoadView(import('@views/device')),
    props: (route) => ({
      signedin: store.getters['auth/loggedIn'],
      id: route.params.id,
    }),
  },
  {
    path: '/workorders',
    name: 'workorders',
    meta: {
      authRequired: true,
    },
    component: () => lazyLoadView(import('@views/work-orders')),
    // Set the location for device list from the route params
    props: (route) => ({
      // locationFilter: route.params.locationFilter,
      // locationIds: route.query.locationIds,
      // locationName: route.params.locationName,
    }),
  },
  {
    path: '/workorder/:id',
    name: 'workorder-detail',
    meta: {
      authRequired: true,
    },
    component: () => lazyLoadView(import('@views/work-order')),
    props: (route) => ({
      signedin: store.getters['auth/loggedIn'],
      woid: route.params.id,
    }),
  },
  {
    path: '/salesorders',
    name: 'salesorders',
    meta: {
      authRequired: true,
    },
    component: () => lazyLoadView(import('@views/sales-orders')),
  },
  {
    path: '/salesorder/:id',
    name: 'salesorder-detail',
    meta: {
      authRequired: true,
    },
    component: () => lazyLoadView(import('@views/sales-order')),
    props: (route) => ({
      signedin: store.getters['auth/loggedIn'],
      id: route.params.id,
    }),
  },
  {
    path: '/new-workorder',
    name: 'new-workorder',
    meta: {
      authRequired: true,
    },
    component: () => lazyLoadView(import('@views/new-workorder')),
  },
  {
    path: '/invoices',
    name: 'invoices',
    meta: {
      authRequired: true,
    },
    component: () => lazyLoadView(import('@views/invoices')),
  },
  {
    path: '/invoice/:id',
    name: 'invoice-detail',
    meta: {
      authRequired: true,
    },
    component: () => lazyLoadView(import('@views/invoice')),
    props: (route) => ({
      signedin: store.getters['auth/loggedIn'],
      id: route.params.id,
    }),
  },
  {
    path: '/purchaseorders',
    name: 'purchaseorders',
    meta: {
      authRequired: true,
    },
    component: () => lazyLoadView(import('@views/purchase-orders')),
  },
  {
    path: '/purchaseorder/:id',
    name: 'purchaseorder-detail',
    meta: {
      authRequired: true,
    },
    component: () => lazyLoadView(import('@views/purchase-order')),
    props: (route) => ({
      signedin: store.getters['auth/loggedIn'],
      id: route.params.id,
    }),
  },
  {
    path: '/bills',
    name: 'bills',
    meta: {
      authRequired: true,
    },
    component: () => lazyLoadView(import('@views/bills')),
  },
  {
    path: '/bill/:id',
    name: 'bill-detail',
    meta: {
      authRequired: true,
    },
    component: () => lazyLoadView(import('@views/bill')),
    props: (route) => ({
      signedin: store.getters['auth/loggedIn'],
      id: route.params.id,
    }),
  },
  {
    path: '/admin',
    component: () => lazyLoadView(import('@views/admin-base')),
    meta: {
      authRequired: true,
      adminRequired: true,
    },
    children: [
      {
        path: 'users',
        name: 'admin-users',
        component: () => lazyLoadView(import('@views/admin/admin-users')),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
      },
      {
        path: 'users/:id',
        name: 'admin-user-detail',
        component: () => lazyLoadView(import('@views/admin/admin-user-detail')),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
        props: (route) => ({
          userId: route.params.id,
        }),
      },
      {
        path: 'organizations',
        name: 'organization-list',
        component: () => lazyLoadView(import('@views/admin/organization-list')),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
      },
      {
        path: 'locations',
        name: 'location-list',
        component: () => lazyLoadView(import('@views/admin/locations')),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
      },
      {
        path: 'location-users',
        name: 'location-users',
        component: () => lazyLoadView(import('@views/admin/admin-location-users')),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
        props: (route) => ({
          locationId: route.query.id,
        }),
      },
      {
        path: 'importarcflash',
        name: 'import-arc-flash',
        component: () => lazyLoadView(import('@views/admin/arc-flash-import')),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
      },
      {
        path: 'importdocument',
        name: 'import-document',
        component: () => lazyLoadView(import('@views/admin/document-import')),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
      },
      {
        path: 'newworkorder',
        name: 'admin-new-workorder',
        component: () =>
          lazyLoadView(import('@views/admin/admin-new-workorder')),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
      },
      {
        path: 'newvendor',
        name: 'admin-new-vendor',
        component: () => lazyLoadView(import('@views/admin/admin-new-vendor')),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
      },
      {
        path: 'newtag',
        name: 'admin-new-tag',
        component: () => lazyLoadView(import('@views/admin/admin-new-tag')),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
      },
      {
        path: 'newcontract',
        name: 'admin-new-contract',
        component: () =>
          lazyLoadView(import('@views/admin/admin-new-contract')),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
      },
      {
        path: 'contractorpermissions/:id',
        name: 'contractor-permissions',
        component: () =>
          lazyLoadView(import('@views/admin/admin-contractor-permission')),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
        props: (route) => ({
          userId: route.params.id,
        }),
      },
      {
        path: 'roles/:id',
        name: 'roles',
        component: () => lazyLoadView(import('@views/admin/roles')),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
        props: (route) => ({
          userId: route.params.id,
        }),
      },
    ],
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => lazyLoadView(import('@views/terms')),
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/_404').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@views/_loading').default,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@views/_timeout').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
