export const state = {
  messages: [],
}

export const getters = {
  messages(state) {
    return state.messages
  },
  // isMessage(state) {
  //   return !!state.message
  // },
}

export const mutations = {
  SET_NEW_MESSAGE(state, { uid, timeout, message, type }) {
    state.messages = [
      ...state.messages,
      {
        id: uid,
        message: message,
        timeout: timeout,
        type: type,
      },
    ]
  },
  REMOVE_MESSAGE(state, id) {
    state.messages = state.messages.filter((message) => {
      return message.id !== id
    })
  },
}

export const actions = {
  setNewMessage({ commit }, { timeout = 6000, message, type = 'info' }) {
    const uid = Math.random() * 46656
    commit('SET_NEW_MESSAGE', { uid, timeout, message, type })
    setTimeout(() => {
      commit('REMOVE_MESSAGE', uid)
    }, timeout)
  },
}
