<script>
export default {
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<template>
  <v-footer height="auto">
    <v-row justify="center" class="navigationButtons">
      <v-btn
        v-for="link in links"
        :key="link.name"
        :to="link"
        active-class
        text
        rounded
        exact
        >{{ link.name }}</v-btn
      >
      <a
        href="https://bowtie8.com/contact-us?utm_source=bowvue&utm_medium=organic&utm_campaign=general&utm_term=na&utm_content=na"
        target="_blank"
      >
        <v-btn text rounded exact> Contact Us</v-btn>
      </a>
      <v-col class="py-3 text-center" cols="12">
        &copy;2014-{{ new Date().getFullYear() }} -
        <strong>Bowtie Engineering, LLC</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>
