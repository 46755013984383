// import Amplify from '@aws-amplify/core'

// export default Amplify.configure({
export default {
  Auth: {
    // mandatorySignIn: false,
    userPoolId: 'us-east-2_PtH5CsW6q',
    identityPoolId: 'us-east-2:232d0e5e-d984-43e5-8df1-42b8815a1340',
    userPoolWebClientId: '4q0ogbn9g8u58f69tjaj29r5td',
    region: 'us-east-2',
    // oauth: {
    //   domain: 'login.bowvue.com',
    //   scope: [
    //     'phone',
    //     'email',
    //     'profile',
    //     'openid',
    //     'aws.cognito.signin.user.admin',
    //   ],
    //   responseType: 'token',
    //   options: {
    //     AdvancedSecurityDataCollectionFlag: true,
    //   },
    // },
  },
}
