<script>
import appConfig from '@src/app.config'

export default {
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title ? `${title} | ${appConfig.title}` : appConfig.title
    },
  },
  data() {
    return {
      registration: null,
      snackbar: false,
      refreshing: false,
    }
  },
  created() {
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },
  beforeDestroy() {
    document.removeEventListener('swUpdated', this.showRefreshUI)
  },
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail
      this.snackbar = true
    },
    refreshApp() {
      this.snackbar = false
      if (!this.registration || !this.registration.waiting) {
        return
      }
      this.registration.waiting.postMessage('skipWaiting')
    },
  },
}
</script>

<template>
  <div id="app">
    <!--
    Even when routes use the same component, treat them
    as distinct and create the component again.
    -->
    <router-view :key="$route.fullPath" />
    <v-snackbar v-model="snackbar" color="#001f3f" bottom right :timeout="-1">
      New Version Available
      <v-spacer />
      <v-btn text @click.native="refreshApp">
        Refresh
      </v-btn>
      <v-btn icon @click="snackbar = false"><v-icon>$close</v-icon></v-btn>
    </v-snackbar>
  </div>
</template>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

@import '~normalize.css/normalize.css';

// @import '~nprogress/nprogress.css';
// ===
// Vendor
// ===

// #nprogress .bar {
//   background: #ff4136;
// }

// Variables
$vue-content-placeholders-primary-color: #ccc !default;
$vue-content-placeholders-secondary-color: #eee !default;
$vue-content-placeholders-border-radius: 6px !default;
$vue-content-placeholders-line-height: 15px !default;
$vue-content-placeholders-spacing: 10px !default;
// Animations
@keyframes vueContentPlaceholdersAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

// Mixins
@mixin vue-content-placeholders {
  position: relative;
  height: $vue-content-placeholders-line-height;
  overflow: hidden;
  background: $vue-content-placeholders-secondary-color;

  .vue-content-placeholders-is-rounded & {
    border-radius: $vue-content-placeholders-border-radius;
  }

  .vue-content-placeholders-is-centered & {
    margin-right: auto;
    margin-left: auto;
  }

  .vue-content-placeholders-is-animated &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 1000px;
    height: 100%;
    content: '';
    background: linear-gradient(
      to right,
      transparent 0%,
      darken($vue-content-placeholders-secondary-color, 5%) 15%,
      transparent 30%
    );
    animation-name: vueContentPlaceholdersAnimation;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

@mixin vue-content-placeholders-spacing {
  [class^='vue-content-placeholders-'] + & {
    margin-top: 2 * $vue-content-placeholders-spacing;
  }
}

// Styles
.vue-content-placeholders-heading {
  @include vue-content-placeholders-spacing;

  display: flex;

  &__img {
    @include vue-content-placeholders;

    width: 2 * $vue-content-placeholders-line-height + 3 *
      $vue-content-placeholders-spacing;
    height: 2 * $vue-content-placeholders-line-height + 3 *
      $vue-content-placeholders-spacing;
    margin-right: 1.5 * $vue-content-placeholders-spacing;
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    @include vue-content-placeholders;

    width: 85%;
    margin-bottom: $vue-content-placeholders-spacing;
    background: $vue-content-placeholders-primary-color;
  }

  &__subtitle {
    @include vue-content-placeholders;

    width: 90%;
  }
}

.vue-content-placeholders-text {
  @include vue-content-placeholders-spacing;

  &__line {
    @include vue-content-placeholders;

    width: 100%;
    margin-bottom: $vue-content-placeholders-spacing;

    &:nth-child(4n + 1) {
      width: 80%;
    }

    &:nth-child(4n + 2) {
      width: 100%;
    }

    &:nth-child(4n + 3) {
      width: 70%;
    }

    &:nth-child(4n + 4) {
      width: 85%;
    }
  }
}

.vue-content-placeholders-img {
  @include vue-content-placeholders;
  @include vue-content-placeholders-spacing;

  width: 100%;
  height: 120px;
}
</style>
