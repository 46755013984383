<script>
import Layout from '@layouts/main'

export default {
  metaInfo: {
    title: 'Loading page...',
    meta: [{ name: 'description', content: 'Loading page...' }],
  },
  components: { Layout },
}
</script>

<template>
  <Layout>
    <v-row class="fill-height" align="center" justify="center" >
      <v-col cols="1">
        <transition appear>
          <v-progress-circular indeterminate />
        </transition>
      </v-col>
    </v-row>
  </Layout>
</template>

<style lang="scss" module>
//@import '@design';

.loadingIcon {
  //@extend .f1, .db, .mh0;

  //display: block;
  //margin: 0 auto;

  // stylelint-disable-next-line selector-class-pattern
  &:global(.v-enter-active) {
    transition: opacity 1s;
  }
  // stylelint-disable-next-line selector-class-pattern
  &:global(.v-enter) {
    opacity: 0;
  }
}
</style>
