import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { persistCache } from 'apollo-cache-persist'
import { apolloLink } from './http-link'

const cache = new InMemoryCache()

// Set up cache persistence
persistCache({
  cache,
  storage: window.localStorage,
})

export default new ApolloClient({
  // link: authLink.concat(httpLink),
  link: apolloLink,
  cache: cache,
  connectToDevTools: process.env.NODE_ENV !== 'production',
})
