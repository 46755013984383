<script>
import { onlineMethods } from '@state/helpers'
export default {
  mounted() {
    window.addEventListener('online', () => {
      this.setOnlineStatus(true)
    })
    window.addEventListener('offline', () => {
      this.setOnlineStatus(false)
    })
  },
  beforeDestroy() {
    window.removeEventListener('online', () => {
      this.setOnlineStatus(true)
    })
    window.removeEventListener('offline', () => {
      this.setOnlineStatus(false)
    })
  },
  methods: {
    ...onlineMethods,
  },
  render: () => null,
}
</script>
