<script>
import { messagesComputed } from '@state/helpers'

export default {
  computed: {
    ...messagesComputed,
  },
}
</script>

<template>
  <v-slide-y-transition group>
    <v-alert
      v-for="(message, index) in messages"
      :key="index"
      value="!!message.uid"
      :type="message.type"
    >
      {{ message.message }}
    </v-alert>
  </v-slide-y-transition>
</template>
