<script>
export default {
  computed: {
    standAlone: () => {
      const userAgent = window.navigator.userAgent.toLowerCase()
      const isIos = /iphone|ipad|ipod/.test(userAgent)

      if (
        isIos &&
        'standalone' in window.navigator &&
        window.navigator.standalone
      ) {
        return true
      }
      if (window.matchMedia('(display-mode: standalone)').matches) {
        return true
      }
      return false
    },
  },
}
</script>

<template>
  <v-bottom-navigation v-if="standAlone" background-color="white" fixed app>
    <v-btn text small @click="$router.go(-1)">
      <v-icon>$prev</v-icon>
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn text small @click="$router.go(1)">
      <v-icon>$next</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
