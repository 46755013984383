import { createHttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { RetryLink } from 'apollo-link-retry'
import ApolloLinkTimeout from 'apollo-link-timeout'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
// import Auth from '@aws-amplify/auth'
import store from '@state/store'

let link = {}

if (process.env.VUE_APP_DEV_ENV === 'staging') {
  link['bowvue_base_uri'] =
    'https://6p9rar596f.execute-api.us-east-2.amazonaws.com/staging'
  link['uri'] = link['bowvue_base_uri'] + '/graphql'
} else if (process.env.NODE_ENV === 'production') {
  link['bowvue_base_uri'] = 'https://api.bowvue.com'
  link['uri'] = link['bowvue_base_uri'] + '/graphql'
} else {
  link['bowvue_base_uri'] = 'http://localhost:8888'
  link['uri'] = link['bowvue_base_uri'] + '/query'
}
console.debug('Link: ', link)
export { link }

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.debug(
        `[GraphQL Error]: Message: ${message}, Locations: ${locations}, Path: ${path}`
      )
    )
  }
  if (networkError) {
    console.debug(`[Network Error]: ${networkError.message}`)
  }
})

const authLink = setContext(async (_, { headers }) => {
  if (
    store &&
    store.state &&
    store.state.auth.currentUser &&
    store.state.auth.currentUser.accessToken
  ) {
    const token = store.state.auth.currentUser.accessToken.jwtToken
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }
  }
})

const timeoutLink = new ApolloLinkTimeout(15000)

const retryIf = (error, operation) => {
  const doNotRetryCodes = [500, 400]
  return !!error && !doNotRetryCodes.includes(error.statusCode)
}

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf,
  },
})

const httpLink = createHttpLink(link)

export const apolloLink = ApolloLink.from([
  authLink,
  retryLink,
  errorLink,
  timeoutLink,
  httpLink,
])
