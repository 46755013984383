// import axios from 'axios'

export const state = {
  // cached: [],
}

export const getters = {
  firstName(state, getters, rootState) {
    const { currentUser } = rootState.auth
    if (currentUser && currentUser.idToken && currentUser.idToken.payload) {
      return currentUser.idToken.payload.given_name
    }
  },
  lastName(state, getters, rootState) {
    const { currentUser } = rootState.auth
    if (currentUser && currentUser.idToken && currentUser.idToken.payload) {
      return currentUser.idToken.payload.family_name
    }
  },
  phoneNumber(state, getters, rootState) {
    const { currentUser } = rootState.auth
    if (currentUser && currentUser.idToken && currentUser.idToken.payload) {
      return currentUser.idToken.payload.phone_number
    }
  },
  id(state, getters, rootState) {
    const { currentUser } = rootState.auth
    if (currentUser && currentUser.idToken && currentUser.idToken.payload) {
      return currentUser.accessToken.payload.username
    }
  }
}

export const mutations = {
  // CACHE_USER(state, newUser) {
  //   state.cached.push(newUser)
  // },
}

export const actions = {
  // fetchUser({ commit, state, rootState }, { email }) {
  //   // 1. Check if we already have the user as a current user.
  //   const { currentUser } = rootState.auth
  //   if (currentUser && currentUser.email === email) {
  //     return Promise.resolve(currentUser)
  //   }
  //   // 2. Check if we've already fetched and cached the user.
  //   const matchedUser = state.cached.find(user => user.email === email)
  //   if (matchedUser) {
  //     return Promise.resolve(matchedUser)
  //   }
  //   // 3. Fetch the user from the API and cache it in case
  //   //    we need it again in the future.
  //   return axios.get(`/users/${email}`).then(response => {
  //     const user = response.data
  //     commit('CACHE_USER', user)
  //     return user
  //   })
  // },
}
