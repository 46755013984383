<script>
// Allows stubbing BaseLink in unit tests
// const BaseLink = 'BaseLink'

export default {
  // Functional components are stateless, meaning they can't
  // have data, computed properties, etc and they have no
  // `this` context.
  functional: true,
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },
  // Render functions are an alternative to templates
  render(h, { props, $style = {} }) {
    function getRouteTitle(route) {
      return typeof route.title === 'function' ? route.title() : route.title
    }
    function getIcon(route) {
      if (route.icon) {
        return h('v-icon', route.icon)
      }
      return null
    }

    // Functional components are the only components allowed
    // to return an array of children, rather than a single
    // root node.
    return props.routes.map((route) => (
      <v-list-item key={route.name} to={route} exact={route.exact}>
        <v-list-item-action>{getIcon(route)}</v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{getRouteTitle(route)}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    ))
  },
}
</script>

<style lang="scss" module>
.active {
  @extend .black, .b, .fw6 !optional;

  /* font-weight: 600;
  color: $color-link-text-active;
  text-decoration: none; */
  cursor: default;
}
</style>
