export const state = {
  online: true,
}

export const getters = {
  isOnline(state) {
    return state.online
  },
}

export const mutations = {
  SET_ONLINE_STATUS(state, status) {
    state.online = !!status
  },
}

export const actions = {
  setOnlineStatus({ commit }, status) {
    commit('SET_ONLINE_STATUS', status)
  },
}
