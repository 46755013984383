// import '@babel/polyfill'
import Vue from 'vue'
import App from './app'
import router from '@router'
import store from '@state/store'
import VueApollo from 'vue-apollo'
import apolloClient from './graphql/apollo-client'
import Amplify from '@aws-amplify/core'
// eslint-disable-next-line
import awsExports from './aws-exports'
// import Cache from '@aws-amplify/cache'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'

Amplify.Logger.LOG_LEVEL =
  process.env.NODE_ENV === 'production' ? 'ERROR' : 'DEBUG'
Amplify.configure(awsExports)

// Vue.use(VCalendar, {})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      fetchPolicy: 'cache-and-network',
    },
  },
})

Vue.use(VueApollo)

// Don't warn about using the dev version of Vue in development
Vue.config.productionTip = process.env.NODE_ENV === 'production'

const app = new Vue({
  vuetify,
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app')

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`
  window.__app__ = app
}
