<script>
import { authComputed, userComputed } from '@state/helpers'
import NavBarRoutes from './nav-bar-routes'

export default {
  components: { NavBarRoutes },
  props: {
    drawer: Boolean,
  },
  data() {
    return {
      homeRoute: {
        name: 'home',
        title: 'Home',
      },
      dashboardRoute: {
        name: 'dashboard',
        title: 'Dashboard',
      },
      persistentNavRoutes: [],
      loggedInNavRoutes: [
        {
          name: 'locations',
          title: 'Locations',
          icon: '$location',
        },
        // {
        //   name: 'devices',
        //   title: 'Devices',
        //   icon: '$devices',
        // },
        // {
        //   name: 'new-workorder',
        //   title: 'New Work Order',
        //   icon: '$workOrder',
        // },
        // {
        //   name: 'workorders',
        //   title: 'Work Orders',
        //   icon: '$workOrder',
        // },
        {
          name: 'profile',
          title: () => this.email,
          icon: '$account',
        },
        {
          name: 'logout',
          title: 'Log out',
          icon: '$logout',
        },
      ],
      loggedOutNavRoutes: [
        {
          name: 'login',
          title: 'Log in',
          icon: '$logout',
        },
      ],
      adminFinancialRoutes: [
        {
          name: 'salesorders',
          title: 'Sales Orders',
          icon: '$workOrder',
        },
        // {
        //   name: 'purchaseorders',
        //   title: 'Purchase Orders',
        //   icon: '$workOrder',
        // },
        // {
        //   name: 'bills',
        //   title: 'Bills',
        //   icon: '$workOrder',
        // },
        {
          name: 'invoices',
          title: 'Invoices',
          icon: '$workOrder',
        },
      ],
      // CHANGE AT A LATER DATE TO MANAGER ONLY
      clientFinancialRoutes: [
        {
          name: 'salesorders',
          title: 'Sales Orders',
          icon: '$workOrder',
        },
        {
          name: 'invoices',
          title: 'Invoices',
          icon: '$workOrder',
        },
      ],
      vendorFinancialRoutes: [
        {
          name: 'purchaseorders',
          title: 'Purchase Orders',
          icon: '$workOrder',
        },
        {
          name: 'bills',
          title: 'Bills',
          icon: '$workOrder',
        },
      ],
      adminNavRoutes: [
        {
          name: 'admin-users',
          title: 'Users',
        },
        {
          name: 'location-list',
          title: 'Locations',
        },
        // {
        //   name: 'admin-new-workorder',
        //   title: 'New Work Order',
        // },
        // {
        //   name: 'admin-new-vendor',
        //   title: 'New Vendor',
        // },
        // {
        //   name: 'admin-new-tag',
        //   title: 'New Tag',
        // },
        // {
        //   name: 'admin-new-contract',
        //   title: 'New Contract',
        // },
        {
          name: 'import-arc-flash',
          title: 'Import Arc Flash',
        },
        // {
        //   name: 'organization-list',
        //   title: 'Organizations',
        // },
        {
          name: 'import-document',
          title: 'Import Document',
        },
      ],
    }
  },
  computed: {
    // ...authLinks,
    ...authComputed,
    ...userComputed,
    isAdminOrAgent() {
      if (
        this.roles !== undefined &&
        this.roles !== null &&
        this.roles.some((r) => r.name === 'ADMIN' || r.name === 'AGENT')
      ) {
        return true
      }
      return false
    },
    isClientManager() {
      if (
        this.roles !== undefined &&
        this.roles !== null &&
        this.roles.some((r) => r.name === 'CLIENT MANAGER')
      ) {
        return true
      }
      return false
    },
    isClient() {
      if (
        this.roles !== undefined &&
        this.roles !== null &&
        this.roles.some((r) => r.name === 'CLIENT')
      ) {
        return true
      }
      return false
    },
    isVendor() {
      if (
        this.roles !== undefined &&
        this.roles !== null &&
        this.roles.some((r) => r.name === 'VENDOR')
      ) {
        return true
      }
      return false
    },
  },
}
</script>

<template>
  <v-navigation-drawer
    :value.sync="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
    fixed
    app
  >
    <v-list dense>
      <v-list-item
        v-if="loggedIn"
        key="dashboard"
        :to="{ name: 'dashboard' }"
        exact
      >
        <v-list-item-action>
          <v-icon>$dashboard</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else key="home" :to="{ name: 'home' }" exact>
        <v-list-item-action>
          <v-icon>$home</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <NavBarRoutes :routes="persistentNavRoutes" />
      <NavBarRoutes v-if="loggedIn" :routes="loggedInNavRoutes" />
      <NavBarRoutes v-else :routes="loggedOutNavRoutes" />
      <v-list-item
        v-if="loggedIn"
        href="/pdfs/moves-adds-changes.pdf"
        download="Moves_Adds_Changes.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        <v-list-item-action>
          <v-icon>$pdfDownload</v-icon>
        </v-list-item-action>
        <v-list-item-title>Moves/Adds/Changes PDF</v-list-item-title>
      </v-list-item>
      <v-list-group v-if="loggedIn && isAdminOrAgent" prepend-icon="$financial">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title> Financials </v-list-item-title>
          </v-list-item-content>
        </template>
        <NavBarRoutes :routes="adminFinancialRoutes" />
      </v-list-group>
      <v-list-group v-else-if="loggedIn && isVendor" prepend-icon="$financial">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title> Financials </v-list-item-title>
          </v-list-item-content>
        </template>
        <NavBarRoutes :routes="vendorFinancialRoutes" />
      </v-list-group>
      <v-list-group
        v-else-if="loggedIn && isClientManager"
        prepend-icon="$financial"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title> Financials </v-list-item-title>
          </v-list-item-content>
        </template>
        <NavBarRoutes :routes="clientFinancialRoutes" />
      </v-list-group>
      <v-list-group v-if="isAdmin" group="/admin" prepend-icon="$lock">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item-content>
        </template>
        <NavBarRoutes :routes="adminNavRoutes" />
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>
