<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('progress', ['isLoading']),
  },
}
</script>

<template>
  <v-progress-linear
    :indeterminate="true"
    :active="isLoading"
    color="error"
    height="2"
    class="my-0"
  >
  </v-progress-linear>
</template>
