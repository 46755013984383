export const state = {
  progress: false,
}

export const getters = {
  isLoading(state) {
    return state.progress
  },
}

export const mutations = {
  CHANGE_PROGRESS(state, progress) {
    state.progress = progress
  },
}

export const actions = {
  startProgress({ commit, state }) {
    commit('CHANGE_PROGRESS', true)
  },
  endProgress({ commit, state }) {
    commit('CHANGE_PROGRESS', false)
  },
}
